<template>
  <div>
    <!-- <div style="height:80px;"></div> -->
    <div style="display:flex;justify-content:center;overflow: scroll;padding-top:70px;" class="message_back" id="article_body">
    <!-- 留言板 -->
    <div class="message_body">
        <!--留言-->
        <!-- <img src="~@/assets/icon/yuedu.png" > -->
        <!--背景幻灯片-->
        <div style="background-color:#f0f2f7;position:relative;height:40vh;"  id="top_height">
          <div style="position:absolute;left:41%;top:30%;display:flex;flex-direction:column;align-items:center;">
            <div style="color:#ffffff;font-size:36px;font-weight:800;">留言板</div>
            <div style="color:#ffffff;font-size:20px;margin-top:24px;">感谢留下您的宝贵意见</div>
          </div>
          <iframe src="/static/wowslider2.html" frameborder="0" style="height:40vh;width:100%;"></iframe>
        </div>
        <!--添加留言-->
        <CommentInfo @heightChange="heightChange" :user_login="user_login" :user_info="user_info" :page_type="1"  :comment_list="comment_list" @getComment="getComment"  @quitLogin="quitLogin" @ifLogin="ifLogin"></CommentInfo>
    </div>
  </div>
</div>

</template>
<script>
import CommentInfo from './components/CommentInfo.vue'
  export default {
    components: {
      CommentInfo
    },
    data() {
      return {
        isShow: false,//验证码弹窗
        upload_img:this.$upload_img,//图片上传地址
        user_login:0,//用户是否登录
        user_info:{},//用户详情
        comment_list:[],//用户留言
      };
    },
    mounted(){
      this.ifLogin();
      this.getComment();
    },
    methods:{
      //添加评论窗口视图高度变化
      heightChange(){
        console.log(document.getElementById('top_height').clientHeight)
        document.getElementById("article_body").scrollTop =  document.getElementById('top_height').clientHeight -50;
      },
      //退出登录
      quitLogin(){
        window.localStorage.removeItem("user_info");
        this.user_login = 0;
        this.user_info = {};
      },
      //判断用户是否登录
      ifLogin(){
        //若存在用户信息
        if(window.localStorage.getItem("user_info")){
          this.user_login = 1;
          this.user_info = JSON.parse(window.localStorage.getItem("user_info")) 
        }else{
          this.user_login = 0;
          this.user_info = {};
        }
      },
      //获取文章评论
      getComment(){
        this.$axios.get(this.$url.getComment).then(res=>{
          this.comment_list = res.data.data;
        })
      },   
    }
  }
</script>
<style scoped>
/* 媒体查询电脑 */
@media screen and (min-width: 768px) {
    .message_body{
      margin-top:10px;
      background-color: #FFFFFF;
      width:1000px;
      height:90vh;
    }
    /*去除最外层滚动条*/
    .message_back::-webkit-scrollbar{
      display:none;
    }
}
/* 媒体查询手机  在480到760之间*/
@media screen and (max-width: 768px) {
    .message_body{
      height:100vh;
      width: 100vw;
    }
}
.submit_btn{
  background-color: rgb(18, 172, 172);
  height:30px;
  width:70px;
  text-align: center;
  line-height:30px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 5px;
}
</style>
<style>
/* 上传图片样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>